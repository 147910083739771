import './contact.css';
import React from 'react';
import Hero from '../components/Hero';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone';
import { Helmet } from 'react-helmet';

const Contact: React.FC = () => {
	return (
		<>
			<Helmet>
				<title>Contactez-nous | AÉCO Pro Sàrl</title>
				<meta
					name="description"
					content="Des questions ? Contactez-nous ! Nous sommes principalement basés à Sierre et à Sion, mais nous travaillons dans tout le Valais."
				/>
			</Helmet>
			<Hero />
			<section className="m-container w-container contact-section" id="contact">
				<div className="contact-container">
					<h2>Contactez-nous</h2>
					<p>
						Nous sommes principalement basés à Sierre et à Sion, mais nous travaillons
						dans tout le Valais.
					</p>
					<p>
						Avez-vous des questions ? Contactez-nous par e-mail ou téléphone et nous
						vous répondrons dans les meilleurs délais.
					</p>
					<div className="infos">
						<div className="contact-info">
							<FontAwesomeIcon icon={faEnvelope} />
							<p>
								E-mail : <a href="mailto:contact@aecopro.ch">contact@aecopro.ch</a>
							</p>
						</div>
						<div className="contact-info">
							<FontAwesomeIcon icon={faPhone} />
							<p>
								Nettoyage : <a href="tel:+41762705890">+41(0)76 270 58 90</a>
							</p>
						</div>
						<div className="contact-info">
							<FontAwesomeIcon icon={faPhone} />
							<p>
								Aménagements extérieurs :{' '}
								<a href="tel:+41782547039">+41(0)78 254 70 39</a>
							</p>
						</div>
						<p>Au cas de non-réponse, veuillez essayer :</p>
						<div className="contact-info">
							<FontAwesomeIcon icon={faPhone} />
							<p>
								Alternatif : <a href="tel:+41774479347">+41(0)77 447 93 47</a>
							</p>
						</div>
					</div>
				</div>
				<iframe
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d44122.89364332895!2d7.463340820059685!3d46.276446426502176!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478f2020b85c55e7%3A0x15ca3d537c4d584e!2sSierre%2C%20Switzerland!5e0!3m2!1sen!2suk!4v1701617401311!5m2!1sen!2suk"
					// width="600"
					// height="450"
					style={{ border: '0', width: '100%', minHeight: '450px' }}
					allowFullScreen={false}
					loading="lazy"
					referrerPolicy="no-referrer-when-downgrade"
				></iframe>
			</section>
		</>
	);
};

export default Contact;
