import React from 'react';
import { Outlet } from 'react-router-dom';
import './Layout.css';
import Header from './Header';
import Footer from './Footer';
import ScrollToTop from './ScrollToTop';

const Layout: React.FC = () => {
	return (
		<div>
			<ScrollToTop />
			<Header />
			<Outlet />
			<Footer />
		</div>
	);
};

export default Layout;
