import React from 'react';
import './amenagement-exterieur.css';
import { travauxQuestions } from '../utils/questions';
import QuestionAccordion from '../components/QuestionAccordion';
import 'video-react/dist/video-react.css';

import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHammer } from '@fortawesome/free-solid-svg-icons';

const Nettoyage: React.FC = () => {
	return (
		<>
			<Helmet>
				<title>Aménagement exterieur | AÉCO Pro Sàrl</title>
				<meta name="description" content="Découvrez nos services d'aménagement exterieur" />
			</Helmet>
			<section className="service-hero black-container m-container" id="hero">
				<FontAwesomeIcon icon={faHammer} size="4x" />

				<h2 className="title">Aménagements extérieurs</h2>
				<p className="description">
					AECO Pro Sàrl métamorphose chaque espace extérieur avec une expertise inégalée.
					Des jardins aux terrasses, une tradition d'excellence. Optez pour la compétence
					et le dévouement, choisissez AECO Pro pour des extérieurs impeccables.
				</p>
			</section>
			<section className="services w-container" id="services">
				<h2>Services offertes</h2>
				<p className="description">
					Proposant une variété de services d'aménagement extérieur, nous vous
					garantissons une transformation impressionnante de votre espace extérieur. Nous
					offrons des solutions personnalisées pour chaque besoin, allant des murs de
					gabion aux talus en fleximur, en passant par la maçonnerie et le génie civil.
					Notre équipe dévouée assure des services d'entretien régulier et de plantation
					d'arbres, tout en proposant des solutions respectueuses de l'environnement. Que
					ce soit pour le déneigement en hiver ou la pose de carrelage en été, nous sommes
					là pour rendre vos espaces extérieurs impeccables.
				</p>
				<a className="btn" href="#exemples">
					Voir les exemples de notre travail
				</a>
				<div className="questions">
					{travauxQuestions.map((question, index) => (
						<QuestionAccordion question={question} key={index} />
					))}
				</div>
			</section>

			<section id="exemples" className="exemples w-container m-container">
				<h2>Exemples de notre travail</h2>
				<p className="description">
					Pour apprécier la qualité de notre travail, nous vous invitons à observer les
					résultats concrets de nos services.
				</p>
				<div className="image-grid">
					<img
						src="/comparisons/amenagement/image-1.jpeg"
						alt=""
						className="image"
						width="300px"
						height="300px"
					/>
					<img
						src="/comparisons/amenagement/image-2.jpeg"
						alt=""
						className="image"
						width="300px"
						height="300px"
					/>
					<img
						src="/comparisons/amenagement/image-3.jpeg"
						alt=""
						className="image"
						width="300px"
						height="300px"
					/>
					<img
						src="/comparisons/amenagement/image-4.jpeg"
						alt=""
						className="image"
						width="300px"
						height="300px"
					/>
					<img
						src="/comparisons/amenagement/image-5.jpeg"
						alt=""
						className="image"
						width="300px"
						height="300px"
					/>
					<img
						src="/comparisons/amenagement/image-6.jpeg"
						alt=""
						className="image"
						width="300px"
						height="300px"
					/>

					<img
						src="/comparisons/amenagement/image-7.jpeg"
						alt=""
						className="image"
						width="300px"
						height="300px"
					/>

					<img
						src="/comparisons/amenagement/image-8.jpeg"
						alt=""
						className="image"
						width="300px"
						height="300px"
					/>
				</div>
			</section>
		</>
	);
};

export default Nettoyage;
