import React from 'react';
import { Outlet, useMatches, useRoutes, Link } from 'react-router-dom';
import './Header.css';
import { faBars, faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Header: React.FC = () => {
	const matches = useMatches();
	const currentMatch = matches[matches.length - 1];
	const [isMobileNavigationOpen, setIsMobileNavigationOpen] = React.useState(false);

	const linkProps = (path: string) => {
		return {
			to: path,
			className: path === currentMatch.pathname ? 'active' : '',
			onClick: () => setIsMobileNavigationOpen(false),
		};
	};

	return (
		<header className="w-container">
			<Link to="/">
				{currentMatch.pathname === '/amenagement-exterieurs' ? (
					<img src="travaux-logo.svg" alt="" width="70%" />
				) : (
					<img src="header-logo.svg" alt="" />
				)}
			</Link>
			<nav>
				<span className="desktop">
					<Link {...linkProps('/')}>Acceuil</Link>

					<Link {...linkProps('/nettoyage')}>Nettoyage</Link>

					<Link {...linkProps('/amenagement-exterieurs')}>Aménagements extérieurs</Link>

					<Link {...linkProps('/contact')}>Contactez-nous</Link>
				</span>
				<span className="mobile">
					<FontAwesomeIcon
						icon={faBars}
						onClick={() => setIsMobileNavigationOpen(true)}
					/>

					<div className={`menu ${isMobileNavigationOpen ? 'active' : ''}`}>
						<div className="menu-close">
							<FontAwesomeIcon
								icon={faClose}
								onClick={() => setIsMobileNavigationOpen(false)}
							/>
						</div>
						<div className="menu-content">
							<Link {...linkProps('/')}>Acceuil</Link>

							<Link {...linkProps('/nettoyage')}>Nettoyage</Link>

							<Link {...linkProps('/amenagement-exterieurs')}>
								Aménagements extérieurs
							</Link>

							<Link {...linkProps('/contact')}>Contactez-nous</Link>
						</div>
					</div>
				</span>
			</nav>
		</header>
	);
};

export default Header;
