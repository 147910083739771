import './QuestionAccordion.css';
import React, { useEffect, useState } from 'react';
import { IQuestion } from '../utils/questions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons/faArrowUp';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons/faArrowDown';

const QuestionAccordion: React.FC<{
	question: IQuestion;
}> = (props) => {
	const [active, setActive] = useState(false);
	const $accordion = React.useRef<HTMLDivElement>(null);

	useEffect(() => {
		if ($accordion.current) {
			$accordion.current.style.maxHeight = active
				? `${$accordion.current.scrollHeight}px`
				: '0px';
		}
	}, [active]);

	return (
		<div className="question-accordion">
			<div
				className={`question-lead ${props.question.answer ? '' : 'no-content'}`}
				onClick={() => setActive(!active)}
			>
				<h3 className="question">{props.question.question}</h3>
				{!!props.question.answer && (
					<span className="icon">
						{active ? (
							<FontAwesomeIcon icon={faArrowUp} />
						) : (
							<FontAwesomeIcon icon={faArrowDown} />
						)}
					</span>
				)}
			</div>
			{!!props.question.answer && (
				<div className={`question-answer ${active ? 'active' : ''}`} ref={$accordion}>
					<p>{props.question.answer}</p>
				</div>
			)}
		</div>
	);
};

export default QuestionAccordion;
