import React from 'react';
import { Outlet, useMatches, useRoutes } from 'react-router-dom';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBroom, faHammer, faSprayCan } from '@fortawesome/free-solid-svg-icons';
import testimonies from '../utils/testimonies';
import { Carousel } from 'react-responsive-carousel';
import chunk from '../utils/chunk';
import useWindowDimensions from '../utils/window';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone';

const Footer: React.FC = () => {
	const matches = useMatches();

	const linkProps = (path: string) => {
		return {
			href: path,
			className: path === matches[matches.length - 1].pathname ? 'active' : '',
		};
	};

	const { width } = useWindowDimensions();

	const $carousel = React.useRef<Carousel>(null);

	React.useEffect(() => {
		if ($carousel.current) {
			$carousel.current.moveTo(0);
		}
	}, [width]);

	return (
		<>
			<div className="gold-container m-container" id="a-propos">
				<section className="qui-est-aeco">
					<h2>Qui est AÉCO Pro ?</h2>
					<p className="description">
						Passionnés et expérimentés dans le nettoyage, la conciergerie et les travaux
						extérieurs, nous offrons des services de qualité, sur mesure pour des
						espaces impeccables. Notre engagement : un travail fiable et méticuleux pour
						votre satisfaction.
					</p>
					<div className="cards">
						<div className="card">
							<div className="content">
								<FontAwesomeIcon icon={faSprayCan} size="4x" />
								<h3>Nettoyeuse</h3>
								<ul>
									<li>Expert du nettoyage avec 25 années d'expérience.</li>
									<li>
										Engagement pour un service impeccable dans vos espaces
										intérieurs.
									</li>
									<li>
										Qualité assurée par l’expertise pour garantir votre
										satisfaction.
									</li>
								</ul>
							</div>
						</div>
						<div className="card">
							<div className="content">
								<FontAwesomeIcon icon={faHammer} size="4x" />
								<h3>Maçon</h3>
								<ul>
									<li>
										Expert en réparation et restauration, dédié à redonner vie à
										vos structures.
									</li>
									<li>
										Spécialisé dans l'entretien extérieur : désherbage,
										enlèvement de neige, et plus encore.
									</li>
									<li>
										Engagement à rendre vos espaces extérieurs impeccables et
										fonctionnels.
									</li>
								</ul>
							</div>
						</div>
					</div>
				</section>
			</div>
			{!!testimonies.length && (
				<section className="avis-clients m-container" id="temoins">
					<h2>Votre satisfaction : garantie</h2>
					<p className="description">
						<i>
							Nous ferons tout pour garantir votre tranquilité d’esprit et
							satisfaction
						</i>
						<br />
						<br />
						Voici les commentaires de nos clients précédents et actuels, si vous voulez
						la preuve de notre expertise.
					</p>
					<div className="carousel-container">
						<Carousel autoPlay ref={$carousel}>
							{/*	split testimonies into groups of 3 and display here*/}
							{chunk(
								testimonies,
								// 1 item per slide on mobile, 2 on tablet, 3 on desktop
								// if the number of testimonies is not divisible by 3, display 2 per slide on tablet
								// as the last slide will only have 1 item and look weird
								width < 768
									? 1
									: width < 1024 || testimonies.length % 3 !== 0
									  ? 2
									  : 3
							).map((group, index) => {
								return (
									<div className="carousel-group" key={index}>
										{group.map((testimony, index) => {
											return (
												<div className="carousel-item" key={index}>
													<p className="testimony">{testimony.content}</p>
													<p className="author">{testimony.author}</p>
													<p className="role">{testimony.role}</p>
												</div>
											);
										})}
									</div>
								);
							})}
						</Carousel>
					</div>
				</section>
			)}
			<div className="footer-container">
				<footer className="w-container">
					<div className="contact">
						<h3>AECO Pro Sàrl</h3>
						<p>Veuillez nous contacter pour toute information complémentaire</p>
						<div className="contact-info">
							<FontAwesomeIcon icon={faEnvelope} />
							<p>
								<a href="mailto:contact@aecopro.ch">contact@aecopro.ch</a>
							</p>
						</div>
						<div className="contact-info">
							<FontAwesomeIcon icon={faPhone} />
							<p>
								<a href="tel:+41762705890">+41(0)76 270 58 90</a>
							</p>
						</div>
						<p>Inscription au registre du commerce : CHE-394.482.062</p>
					</div>
					<div className="logos">
						<div className="logo">
							<p>Services de nettoyage</p>
							<img src="nettoyage-logo.svg" alt="" />
						</div>
						<div className="logo">
							<p>Services d'aménagements extérieurs</p>
							<img src="travaux-logo.svg" alt="" />
						</div>
					</div>
				</footer>
			</div>
		</>
	);
};

export default Footer;
