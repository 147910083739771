import { RouteObject } from 'react-router-dom';
import Layout from './components/Layout';
import Index from './routes';
import Nettoyage from './routes/nettoyage';
import AmenagementExterieurs from './routes/amenagement-exterieur';
import Contact from './routes/contact';

export const navigation: RouteObject[] = [
	{
		path: '/',
		element: <Layout />,
		children: [
			{
				index: true,
				element: <Index />,
			},
			{
				path: '/amenagement-exterieurs',
				element: <AmenagementExterieurs />,
			},
			{
				path: '/nettoyage',
				element: <Nettoyage />,
			},
			{
				path: '/contact',
				element: <Contact />,
			},
		],
	},
];
