const testimonies: {
	content: string;
	author: string;
	role: string;
}[] = [
	// {
	// 	content:
	// 		'Offendit taciti tamquam intellegat montes facilisis inimicus veniam. Luctus quod graece error augue tale liber nisl expetendis habitant. Recteque placerat imperdiet quis vel. Perpetua explicari postulant sapien urna voluptatibus graecis vis nihil. Inceptos cum habeo definiebas quas docendi.',
	// 	author: 'Thomas Raphaël',
	// 	role: 'CEO',
	// },
	// {
	// 	content:
	// 		'Detraxit risus ridiculus sociis idque viris mattis posse. Graeco dolore definiebas adipiscing rhoncus tellus iudicabit. Antiopam nonumy cursus lacus altera nonumy veniam ancillae. Eloquentiam vidisse option quaestio et. Wisi quod cursus neglegentur instructior morbi alterum idque dicant.',
	// 	author: 'M Nathan Rodriguéz',
	// 	role: 'CTO',
	// },
	// {
	// 	content:
	// 		'Quisque quaeque taciti quaeque. Ei quodsi voluptua accusam. Ei eam eum nihil. Ei qui utinam accusam. Ei quodsi voluptua accusam. Ei eam eum nihil. Ei qui utinam accusam.',
	// 	author: 'M Nathan Rodriguéz',
	// 	role: 'CTO',
	// },
	// {
	// 	content:
	// 		'Dicam quo adolescens lacus eget eripuit audire repudiare appareat. Corrumpit neglegentur nisi gravida utroque quod scripta doming perpetua atqui. Honestatis lorem invidunt eam cubilia est intellegat. Vim saperet ornatus amet alienum. Libero molestie sem parturient in iusto.',
	// 	author: 'Bérnard Louna',
	// 	role: 'CFO',
	// },
];

export default testimonies;
