import React from 'react';
import logo from './logo.svg';
import './App.css';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import {navigation} from "./router";

function App() {
  const router = createBrowserRouter(navigation);
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
