import React, { useState } from 'react';
import './ImageComparison.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsLeftRight } from '@fortawesome/free-solid-svg-icons/faArrowsLeftRight';

type IImageComparisonProps = {
	before: string;
	after: string;
	description?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const ImageComparison: React.FC<IImageComparisonProps> = (props) => {
	const { before, after, description, ...rest } = props;
	const [width, setWidth] = useState(50);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setWidth(Number(e.target.value));
	};

	return (
		<div {...rest} className={`${rest.className} comparison-image`}>
			{description ? <p className="description">{description}</p> : null}
			<div className="comparison-image-container">
				<div
					className="before-image-container"
					style={{
						width: `${width}%`,
					}}
				>
					<div className="label-container">
						<p className="label">Avant</p>
					</div>
					<img className="before-image" src={before} alt="" />
				</div>
				<div className="after-image-container">
					<div className="label-container">
						<p className="label">Après</p>
					</div>
					<img className="after-image" src={after} alt="" />
				</div>
				<input
					type="range"
					min={0}
					max={100}
					value={width}
					className="slider"
					name="slider"
					onChange={handleChange}
					style={{
						appearance: 'none',
						backgroundColor: 'transparent',
						width: '100%',
						height: ' 100%',
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						cursor: 'inherit',
						zIndex: 3,
					}}
				/>
				<div
					className="slider-icon"
					style={{
						backgroundColor: 'var(--gold)',
						pointerEvents: 'none',
						position: 'absolute',
						top: '50%',
						left: `${width}%`,
						transform: `translate(-50%,-50%)`,
						borderRadius: '50%',
						width: 30,
						height: 30,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						zIndex: 3,
					}}
				>
					<FontAwesomeIcon icon={faArrowsLeftRight} color="white" />
				</div>
			</div>
		</div>
	);
};

export default ImageComparison;
