import React from 'react';
import './index.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Link } from 'react-router-dom';
import Hero from '../components/Hero';

const Index: React.FC = () => {
	return (
		<>
			<Hero />
			<section className="prestations w-container" id="prestations">
				<h2>Nos prestations</h2>
				<p className="description">
					Découvrez nos services variés pour répondre à vos besoins. De la conciergerie au
					nettoyage, en passant par les travaux extérieurs, notre large gamme de
					prestations sur mesure assure un entretien professionnel pour des espaces
					impeccables.
				</p>
				<div className="cards">
					<div className="card nettoyage">
						<div className="content">
							<h3>Nettoyage</h3>
							<p>
								Nettoyage professionnel et écologique. Votre espace impeccable, sans
								compromis sur l'environnement.
							</p>
							<Link to="/nettoyage">En savoir plus</Link>
						</div>
					</div>
					<div className="card travaux">
						<div className="content">
							<h3>Travaux</h3>
							<p>
								Expertise artisanale pour vos travaux extérieurs. Qualité,
								durabilité et savoir-faire au service de votre espace extérieur.
							</p>
							<Link to="/amenagement-exterieurs">En savoir plus</Link>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Index;
